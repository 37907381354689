import './App.css';
import LogInPage from './LogIn';
import Header from "./shared/header"
import Footer from "./shared/footer"
function App() {
  return (
    <div>
      <Header/>
      <LogInPage/>
      <Footer/>
    </div>
  );
}

export default App;
