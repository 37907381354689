import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaCheck } from "react-icons/fa";

const LogInPage = () => {
  const [phone, setPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        {/* Button to Open Modal */}
        <button
          onClick={openModal}
          className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Open Modal
        </button>

        {/* Modal */}
        {isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-[48%]">
              <div className="p-4 items-center grid grid-cols-2">
                <div className="flex items-center space-x-4">
                  <div>
                    <IoMdClose className="rounded-full p-2 border border-zinc-200 w-10 h-10 " />
                  </div>
                  <div className="flex-col space-y-3">
                    <p className="text-xl font-semibold">Login or Register</p>
                    <p className="text-md">Please fill Mobile number</p>
                  </div>
                </div>

                <div className="flex justify-end">
                  <p className="text-blue-600 font-medium">Help?</p>
                </div>
              </div>

              <div className="grid grid-cols-2 border-t">
                <div className="bg-gray-100 p-4 rounded-lg">
                  <p className="text-md font-semibold">Mobile Number</p>

                  <PhoneInput
                    className="rounded-lg mt-4"
                    country={"us"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      fontSize: "16px",
                    }}
                    dropdownStyle={{
                      zIndex: 1000,
                    }}
                  />

                  <button className="bg-blue-600 text-white font-medium rounded-md w-full flex justify-center items-center py-3 mt-4">
                    Next
                  </button>

                  <div className="flex flex-col min-h-40">
                    <div className="mt-auto text-center mb-4">
                      <p>By using Beseqar you agree to</p>
                      <p>
                        <span className="text-blue-600">Terms of Use</span> and{" "}
                        <span className="text-blue-600">Content Policy</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-4">
                  <p className="text-md">The best way to</p>
                  <p className="text-xl font-semibold mt-2">
                    Sell or Buy Anything
                  </p>

                  <div className="flex space-x-2 mt-6">
                    <FaCheck className="w-6 h-6 p-1 text-lime-600 border rounded-full" />
                    <p>Join Millions of people using Beseqar</p>
                  </div>
                  <div className="flex space-x-2 mt-4">
                    <FaCheck className="w-6 h-6 p-1 text-lime-600 border rounded-full" />
                    <p>Promote trust and safety by loging in</p>
                  </div>
                  <div className="flex space-x-2 mt-4 items-center">
                    <FaCheck className="w-6 h-6 p-1 text-lime-600 border rounded-full" />
                    <p>Check and respond to chats, replies and offers</p>
                  </div>
                  <div className="flex space-x-2 mt-4">
                    <FaCheck className="w-6 h-6 p-1 text-lime-600 border rounded-full" />
                    <p>Manage your favourite and safety listings</p>
                  </div>
                  <div className="flex space-x-2 mt-4">
                    <FaCheck className="w-6 h-6 p-1 text-lime-600 border rounded-full" />
                    <p>Earn money and manage your listings</p>
                  </div>
                </div>
              </div>
              {/* <div className="flex justify-end p-4">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
                >
                  Close
                </button>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogInPage;
